import { zIndex } from '@common/size';
import { css, cx } from '@emotion/css';
import { useInterval, useLoading } from '@topremit/shared-web/hooks';
import { useEffect, useState } from 'react';

const styled = {
  root: (progress: number) => css`
    position: fixed;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    top: 0;
    z-index: ${zIndex.loadingScreen};
    &::after {
      position: absolute;
      content: ' ';
      width: ${progress}%;
      height: 0.1875rem;
      left: 0;
      top: 0;
      background: var(--blue-500);
      transition: width ease-in 0.2s;
    }
  `,
};

let current_progress = 0;
const step = 0.5;

function LoadingScreen() {
  const [progress, setProgress] = useState(0);
  const [isLoading] = useLoading();

  const { clearInterval, setInterval } = useInterval();

  const rootWrapperClass = cx(styled.root(progress));

  useEffect(() => {
    if (isLoading) {
      setInterval(() => {
        current_progress += step;
        setProgress(
          Math.round(
            (Math.atan(current_progress) / (Math.PI / 2)) * 100 * 1000,
          ) / 1000,
        );
      }, 100);
    } else {
      current_progress = 0;
      setProgress(0);
      clearInterval();
    }
  }, [isLoading]);

  return isLoading ? <div className={rootWrapperClass} /> : <></>;
}

export default LoadingScreen;
